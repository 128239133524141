
import { motion } from "framer-motion";
import { useLanguage } from "../contexts/LanguageContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";

const Contact = () => {
  const { language } = useLanguage();

  const content = {
    orders: language === 'sv' ? 'Beställningar' : 'Orders',
    orderFuel: language === 'sv' ? 'Beställningsförfrågan Drivmedel' : 'Order Fuel',
    description: language === 'sv'
      ? 'Vi kommer att bekräfta leveransen på mail eller på telefon och återkommer eventuellt om det är några frågor. I normalfallet så behöver vi beställningen 3 arbetsdagar före leveransen.'
      : 'We will confirm the delivery by email or telephone and will get back to you if there are any questions. Normally, we need the order 3 working days before delivery.',
    nameCompany: language === 'sv' ? 'Namn/Företag' : 'Name/Company',
    email: language === 'sv' ? 'E-postadress' : 'Email',
    product: language === 'sv' ? 'Önskad Produkt' : 'Desired Product',
    volume: language === 'sv' ? 'Önskad Volym (liter)' : 'Desired Volume (liters)',
    location: language === 'sv' ? 'Leveransplats' : 'Delivery Location',
    date: language === 'sv' ? 'Datum' : 'Date',
    additionalInfo: language === 'sv' ? 'Övrig information' : 'Additional information',
    sendMessage: language === 'sv' ? 'Skicka beställning' : 'Send order',
    namePlaceholder: language === 'sv' ? 'Ert namn eller företag' : 'Your name or company',
    emailPlaceholder: language === 'sv' ? 'Er e-postadress' : 'Your email',
    locationPlaceholder: language === 'sv' ? 'Ange leveransplats' : 'Enter delivery location',
    additionalInfoPlaceholder: language === 'sv' ? 'Övrig information som kan vara relevant för beställningen' : 'Any additional information relevant to your order',
    postalAddress: language === 'sv' ? 'Postadress' : 'Postal Address',
    visitingAddress: language === 'sv' ? 'Besöksadress' : 'Visiting Address',
    fuelOrders: language === 'sv' ? 'Beställning av drivmedel' : 'Fuel Orders',
    economyAdmin: language === 'sv' ? 'Ekonomi & Administration' : 'Economy & Administration'
  };

  const products = [
    "HVO100",
    language === 'sv' ? "Bensin 98" : "Petrol 98",
    language === 'sv' ? "Diesel utan RME" : "Diesel without RME",
    language === 'sv' ? "Diesel MK1 färgad" : "Diesel MK1 coloured",
    language === 'sv' ? "Gasoil E10 färgad" : "Gasoil E10 coloured",
    language === 'sv' ? "Gasoil E32 färgad" : "Gasoil E32 coloured"
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name');
    const email = formData.get('email');
    const product = formData.get('product');
    const volume = formData.get('volume');
    const location = formData.get('location');
    const date = formData.get('date');
    const additionalInfo = formData.get('additionalInfo');
    
    const message = `
${content.nameCompany}: ${name}
${content.product}: ${product}
${content.volume}: ${volume}
${content.location}: ${location}
${content.date}: ${date}
${content.additionalInfo}: ${additionalInfo}
    `;
    
    window.location.href = `mailto:leveranser@petrobell.se?subject=${language === 'sv' ? 'Beställningsförfrågan från' : 'Order request from'} ${name}&body=${encodeURIComponent(message)}`;
  };

  return (
    <section id="order-form" className="py-20 relative bg-primary">
      <div className="absolute inset-0 z-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover opacity-30"
          src="https://storage.googleapis.com/petrobell_hemsida_produkter/video/Hav12%2C5sek.mp4"
        >
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-primary/70"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <span className="inline-block px-4 py-1 mb-4 text-sm font-medium bg-white rounded-full text-primary">
              {content.orders}
            </span>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              {content.orderFuel}
            </h2>
            <p className="text-white mb-8">
              {content.description}
            </p>
          </div>

          <motion.form
            onSubmit={handleSubmit}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="space-y-6 mb-16 bg-white/10 p-8 rounded-lg backdrop-blur-sm"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  {content.nameCompany}
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-white/50 transition-all placeholder:text-white/60"
                  placeholder={content.namePlaceholder}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  {content.email}
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-white/50 transition-all placeholder:text-white/60"
                  placeholder={content.emailPlaceholder}
                />
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  {content.product}
                </label>
                <Select name="product" required>
                  <SelectTrigger className="w-full border-white/30 bg-white/10 text-white focus:ring-white/50">
                    <SelectValue placeholder=" " />
                  </SelectTrigger>
                  <SelectContent className="bg-primary/90 border-white/30">
                    {products.map((product) => (
                      <SelectItem key={product} value={product} className="text-white hover:bg-white/10 focus:bg-white/10">
                        {product}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  {content.volume}
                </label>
                <input
                  type="number"
                  name="volume"
                  required
                  min="1"
                  className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-white/50 transition-all"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  {content.location}
                </label>
                <input
                  type="text"
                  name="location"
                  required
                  className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-white/50 transition-all placeholder:text-white/60"
                  placeholder={content.locationPlaceholder}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  {content.date}
                </label>
                <input
                  type="date"
                  name="date"
                  required
                  className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-white/50 transition-all"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-white mb-2">
                {content.additionalInfo}
              </label>
              <Textarea
                name="additionalInfo"
                className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-white/50 transition-all min-h-[100px] placeholder:text-white/60"
                placeholder={content.additionalInfoPlaceholder}
              />
            </div>

            <Button
              type="submit"
              className="w-full px-8 py-4 bg-white text-primary rounded-lg font-medium transition-all hover:bg-white/90 hover:shadow-lg"
            >
              {content.sendMessage}
            </Button>
          </motion.form>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-center bg-white/10 p-8 rounded-lg backdrop-blur-sm">
            <div>
              <h3 className="font-bold text-xl mb-4 text-white">{content.postalAddress}</h3>
              <p className="text-white/90">
                Petrobell AB<br />
                Ranhammarsvägen 4<br />
                168 67 Bromma
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-4 text-white">{content.visitingAddress}</h3>
              <p className="text-white/90">
                Petrobell AB<br />
                Ranhammarsvägen 4<br />
                168 67 Bromma
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-4 text-white">{content.fuelOrders}</h3>
              <p className="text-white/90">
                <a href="mailto:leveranser@petrobell.se" className="hover:text-white">leveranser@petrobell.se</a><br />
                <a href="tel:+46705376100" className="hover:text-white">070-537 61 00</a>
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-4 text-white">{content.economyAdmin}</h3>
              <p className="text-white/90">
                <a href="mailto:Fredrik@petrobell.se" className="hover:text-white">Fredrik@petrobell.se</a><br />
                <a href="mailto:Christoffer@petrobell.se" className="hover:text-white">Christoffer@petrobell.se</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
