import { useState } from "react";
import { motion } from "framer-motion";
import { MainNav } from "../components/MainNav";
import { useLanguage } from "../contexts/LanguageContext";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X, FileText, Shield } from "lucide-react";
import { useIsMobile } from "../hooks/use-mobile";

const ProductsPage = () => {
  const { language } = useLanguage();
  const [openProductIndex, setOpenProductIndex] = useState<number | null>(null);
  const [openFileIndex, setOpenFileIndex] = useState<number | null>(null);
  const isMobile = useIsMobile();

  const products = [
    {
      name: "HVO100",
      files: [
        language === 'sv' ? "Produktdatablad" : "Product Data Sheet",
        language === 'sv' ? "Säkerhetsdatablad" : "Safety Data Sheet"
      ]
    },
    {
      name: language === 'sv' ? "Bensin 98" : "Petrol 98",
      files: [
        language === 'sv' ? "Produktdatablad" : "Product Data Sheet",
        language === 'sv' ? "Säkerhetsdatablad" : "Safety Data Sheet"
      ]
    },
    {
      name: language === 'sv' ? "Diesel utan RME" : "Diesel without RME",
      files: [
        language === 'sv' ? "Produktdatablad" : "Product Data Sheet",
        language === 'sv' ? "Säkerhetsdatablad" : "Safety Data Sheet"
      ]
    },
    {
      name: language === 'sv' ? "Diesel MK1 färgad" : "Diesel MK1 coloured",
      files: [
        language === 'sv' ? "Produktdatablad" : "Product Data Sheet",
        language === 'sv' ? "Säkerhetsdatablad" : "Safety Data Sheet"
      ]
    },
    {
      name: language === 'sv' ? "Gasoil E10 färgad" : "Gasoil E10 coloured",
      files: [
        language === 'sv' ? "Produktdatablad" : "Product Data Sheet",
        language === 'sv' ? "Säkerhetsdatablad" : "Safety Data Sheet"
      ]
    },
    {
      name: language === 'sv' ? "Gasoil E32 färgad" : "Gasoil E32 coloured",
      files: [
        language === 'sv' ? "Produktdatablad" : "Product Data Sheet",
        language === 'sv' ? "Säkerhetsdatablad" : "Safety Data Sheet"
      ]
    }
  ];

  const handleFileClick = (productIndex: number, fileIndex: number) => {
    setOpenProductIndex(productIndex);
    setOpenFileIndex(fileIndex);
  };

  const closeDialog = () => {
    setOpenProductIndex(null);
    setOpenFileIndex(null);
  };

  const getFileContent = (productIndex: number, fileIndex: number) => {
    if (productIndex === 0 && fileIndex === 0) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/produktdatablad/HVO100.pdf" 
            className="w-full h-full border-0"
            title="HVO100 Produktdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 1 && fileIndex === 0) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/produktdatablad/Bensin%2098.pdf" 
            className="w-full h-full border-0"
            title="Bensin 98 Produktdatablad"
          />
        </div>
      );
    }

    if (productIndex === 2 && fileIndex === 0) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/produktdatablad/Diesel%20utan%20RME.pdf" 
            className="w-full h-full border-0"
            title="Diesel utan RME Produktdatablad"
          />
        </div>
      );
    }

    if (productIndex === 3 && fileIndex === 0) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/produktdatablad/Diesel%20MK1%20fa%CC%88rgad.pdf" 
            className="w-full h-full border-0"
            title="Diesel MK1 färgad Produktdatablad"
          />
        </div>
      );
    }

    if (productIndex === 4 && fileIndex === 0) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/produktdatablad/Gasoil%20E10%20fa%CC%88rgad.pdf" 
            className="w-full h-full border-0"
            title="Gasoil E10 färgad Produktdatablad"
          />
        </div>
      );
    }

    if (productIndex === 5 && fileIndex === 0) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/produktdatablad/Gasoil%20E32%20fa%CC%88rgad.pdf" 
            className="w-full h-full border-0"
            title="Gasoil E32 färgad Produktdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 0 && fileIndex === 1) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/s%C3%A4kerhetsdatablad/HVO100.pdf" 
            className="w-full h-full border-0"
            title="HVO100 Säkerhetsdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 1 && fileIndex === 1) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/s%C3%A4kerhetsdatablad/Bensin%2098%20Extra.pdf" 
            className="w-full h-full border-0"
            title="Bensin 98 Säkerhetsdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 2 && fileIndex === 1) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/s%C3%A4kerhetsdatablad/Diesel%20Ofa%CC%88rgad.pdf" 
            className="w-full h-full border-0"
            title="Diesel utan RME Säkerhetsdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 3 && fileIndex === 1) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/s%C3%A4kerhetsdatablad/Eldningsolja%20Miljo%CC%88.pdf" 
            className="w-full h-full border-0"
            title="Diesel MK1 färgad Säkerhetsdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 4 && fileIndex === 1) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/s%C3%A4kerhetsdatablad/Eldningsolja%20E10.pdf" 
            className="w-full h-full border-0"
            title="Gasoil E10 färgad Säkerhetsdatablad"
          />
        </div>
      );
    }
    
    if (productIndex === 5 && fileIndex === 1) {
      return (
        <div className="w-full h-[80vh]">
          <iframe 
            src="https://storage.googleapis.com/petrobell_hemsida_produkter/s%C3%A4kerhetsdatablad/Eldningsolja%20E32.pdf" 
            className="w-full h-full border-0"
            title="Gasoil E32 färgad Säkerhetsdatablad"
          />
        </div>
      );
    }
    
    return (
      <div className="flex flex-col items-center justify-center h-full p-6">
        <p className="text-lg text-gray-600 mb-4">
          {language === 'sv' 
            ? "Dokumentet är inte tillgängligt just nu." 
            : "Document is not available at the moment."}
        </p>
      </div>
    );
  };

  return (
    <div className="min-h-screen w-full">
      <MainNav />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen w-full"
      >
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <h2 className="text-3xl font-bold text-primary mb-6">
                {language === 'sv' ? 'Produkter' : 'Products'}
              </h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {products.map((product, productIndex) => (
                  <div key={productIndex} className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                    <div className="bg-primary p-4">
                      <h3 className="text-xl font-semibold text-white">{product.name}</h3>
                    </div>
                    <div className="p-4">
                      <div className="flex flex-col space-y-3">
                        {product.files.map((file, fileIndex) => (
                          <button
                            key={fileIndex}
                            onClick={() => handleFileClick(productIndex, fileIndex)}
                            className="flex items-center text-left p-2 hover:bg-gray-50 rounded-md transition-colors text-gray-700 hover:text-primary"
                          >
                            {fileIndex === 0 ? (
                              <FileText className="h-5 w-5 mr-2 text-primary" />
                            ) : (
                              <Shield className="h-5 w-5 mr-2 text-primary" />
                            )}
                            <span>{file}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </motion.div>

      <Dialog open={openProductIndex !== null} onOpenChange={(open) => !open && closeDialog()}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto flex flex-col">
          <DialogTitle className="flex justify-between items-center">
            {openProductIndex !== null && openFileIndex !== null && (
              <span>{products[openProductIndex].name} - {products[openProductIndex].files[openFileIndex]}</span>
            )}
            <Button
              variant="ghost"
              size="icon"
              onClick={closeDialog}
              className="h-6 w-6 rounded-full absolute right-4 top-4"
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogTitle>
          <div className="mt-4 overflow-y-auto">
            {openProductIndex !== null && openFileIndex !== null && 
              getFileContent(openProductIndex, openFileIndex)
            }
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductsPage;
