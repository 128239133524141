
import { motion } from "framer-motion";
import { useLanguage } from "../contexts/LanguageContext";

const About = () => {
  const { language } = useLanguage();

  const content = {
    aboutUs: language === 'sv' ? 'Om Oss' : 'About Us',
    title: language === 'sv' 
      ? 'Säker drivmedelshantering i marin miljö' 
      : 'Safe fuel handling in marine environment',
    description: language === 'sv'
      ? 'Drivmedelshantering i marin miljö ställer höga krav avseende säkerhet, miljö och servicenivå. Med vår expertis och dedikerade flotta av bunkerfartyg och tankbilar säkerställer vi pålitliga leveranser av marina drivmedel till hela Sverige.'
      : 'Fuel handling in marine environments demands high standards regarding safety, environment, and service level. With our expertise and dedicated fleet of bunker vessels and tank trucks, we ensure reliable deliveries of marine fuels throughout Sweden.',
    readMore: language === 'sv' ? 'Läs mer om oss' : 'Read more about us'
  };

  return (
    <section className="py-20 bg-[url('/lovable-uploads/677f5afd-369a-4d42-bcf1-87c90edd5c0f.png')] bg-cover bg-center bg-fixed">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
          >
            <div className="bg-white/90 p-8 rounded-lg backdrop-blur-sm">
              <span className="inline-block px-4 py-1 mb-4 text-sm font-medium bg-white rounded-full text-primary">
                {content.aboutUs}
              </span>
              <h2 className="text-3xl md:text-4xl font-bold text-primary mb-6">
                {content.title}
              </h2>
              <p className="text-gray-600 mb-6">
                {content.description}
              </p>
              <motion.a
                href="/about"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="inline-block px-6 py-3 bg-primary text-white rounded-lg font-medium transition-all hover:shadow-lg"
              >
                {content.readMore}
              </motion.a>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              viewport={{ once: true }}
              className="relative h-[400px] rounded-2xl overflow-hidden"
            >
              <img
                src="/lovable-uploads/c0289497-97f7-4f08-bb53-8a6a1037b4c0.png"
                alt={language === 'sv' ? "Drivmedelsanslutning" : "Fuel connection"}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-primary/5"></div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
