
import { motion } from "framer-motion";
import { useLanguage } from "../contexts/LanguageContext";

const Hero = () => {
  const { language } = useLanguage();

  const content = {
    title: language === 'sv' ? 'Specialister på marina drivmedel' : 'Marine Fuel Specialists',
    description: language === 'sv'
      ? 'Vi levererar alla sorters drivmedel i hela Sverige med bunkerfartyg och tankbilar till sjöstationer, fartyg, entreprenader och statliga myndigheter. Vi erbjuder även leveranser av större volymer och har kapacitet att möta behoven hos större fartyg och kunder.'
      : 'We deliver all types of fuels throughout Sweden with bunker vessels and tank trucks to marine stations, vessels, contractors, and government agencies. We also offer deliveries of larger volumes and have the capacity to meet the needs of larger vessels and customers.',
    orderButton: language === 'sv' ? 'Beställningsförfrågan Drivmedel' : 'Order Fuel'
  };

  const scrollToOrderForm = () => {
    const orderForm = document.getElementById('order-form');
    if (orderForm) {
      orderForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative h-[70vh] flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 z-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
          src="https://storage.googleapis.com/petrobell_hemsida_produkter/video/Hav12%2C5sek.mp4"
        >
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black/30"></div>
      </div>
      
      <div className="container mx-auto px-4 z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center"
        >
          <img 
            src="https://image.jimcdn.com/app/cms/image/transf/dimension=574x10000:format=png/path/s411a2b5b18fcb029/image/ibf520ed4bd6a92ca/version/1505397001/image.png" 
            alt="Petrobell Logo" 
            className="h-24 mx-auto mb-8"
          />
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 tracking-tight">
            {content.title}
          </h1>
          <p className="text-lg md:text-xl text-white/90 max-w-2xl mx-auto mb-8">
            {content.description}
          </p>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={scrollToOrderForm}
            className="px-12 py-4 text-lg bg-primary text-white rounded-lg font-medium transition-all hover:shadow-lg"
          >
            {content.orderButton}
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
