import { motion } from "framer-motion";
import { MainNav } from "../components/MainNav";
import Hero from "../components/Hero";
import About from "../components/About";
import Contact from "../components/Contact";

const Index = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-white"
    >
      <MainNav />
      <Hero />
      <About />
      <Contact />
    </motion.div>
  );
};

export default Index;