
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu"
import { cn } from "@/lib/utils"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useIsMobile } from "@/hooks/use-mobile"
import { Menu } from "lucide-react"
import { useState } from "react"
import { useLanguage } from "../contexts/LanguageContext"
import { Button } from "./ui/button"

export function MainNav() {
  const isMobile = useIsMobile();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToOrderForm = () => {
    // Close the mobile menu if it's open
    setIsMenuOpen(false);
    
    // If we're not on the homepage, navigate there first
    if (location.pathname !== '/') {
      navigate('/');
      // Use setTimeout to allow navigation to complete before scrolling
      setTimeout(() => {
        const orderForm = document.getElementById('order-form');
        if (orderForm) {
          orderForm.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      // Already on homepage, just scroll
      const orderForm = document.getElementById('order-form');
      if (orderForm) {
        orderForm.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const scrollToShips = () => {
    setIsMenuOpen(false);
    
    // If we're not on the about page, navigate there first
    if (location.pathname !== '/about') {
      navigate('/about');
      // Use setTimeout to allow navigation to complete before scrolling
      setTimeout(() => {
        const shipsSection = document.querySelector('[data-section="ships"]');
        if (shipsSection) {
          shipsSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      // Already on about page, just scroll
      setTimeout(() => {
        const shipsSection = document.querySelector('[data-section="ships"]');
        if (shipsSection) {
          shipsSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  const navItems = [
    { to: "/", label: language === 'sv' ? "Hem" : "Home" },
    { to: "/products", label: language === 'sv' ? "Produkter" : "Products" },
    { to: "/about", label: language === 'sv' ? "Om oss" : "About us" },
    { to: "/about", label: language === 'sv' ? "Våra Fartyg" : "Our Ships", onClick: scrollToShips },
    { label: language === 'sv' ? "Beställningar" : "Orders", onClick: scrollToOrderForm, isButton: true }
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-[200]">
      <div className="bg-primary w-full">
        <div className="container mx-auto flex justify-between items-center">
          {isMobile ? (
            <div className="relative w-full h-20 flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="absolute left-4 text-primary-foreground hover:text-primary-foreground/80 transition-colors"
              >
                <Menu size={24} />
              </button>
              {isMenuOpen && (
                <div className="absolute top-full left-0 w-full bg-primary shadow-lg py-2 animate-fade-down">
                  {navItems.map((item, index) => (
                    item.isButton ? (
                      <button
                        key={index}
                        onClick={item.onClick}
                        className="w-full text-left px-4 py-2 text-primary-foreground hover:bg-primary-foreground/10 transition-colors text-sm"
                      >
                        {item.label}
                      </button>
                    ) : (
                      <Link
                        key={index}
                        to={item.to}
                        onClick={item.onClick}
                        className="block px-4 py-2 text-primary-foreground hover:bg-primary-foreground/10 transition-colors text-sm"
                      >
                        {item.label}
                      </Link>
                    )
                  ))}
                </div>
              )}
              <Button
                variant="secondary"
                size="sm"
                onClick={toggleLanguage}
                className="absolute right-4 bg-white text-primary hover:bg-white/90"
              >
                {language === 'sv' ? 'EN' : 'SV'}
              </Button>
            </div>
          ) : (
            <>
              <NavigationMenu className="py-4">
                <NavigationMenuList className="gap-6">
                  {navItems.map((item, index) => (
                    <NavigationMenuItem key={index}>
                      {item.isButton ? (
                        <button
                          onClick={item.onClick}
                          className="text-primary-foreground hover:text-primary-foreground/80 transition-colors text-sm md:text-base"
                        >
                          {item.label}
                        </button>
                      ) : (
                        <Link
                          to={item.to}
                          onClick={item.onClick}
                          className="text-primary-foreground hover:text-primary-foreground/80 transition-colors text-sm md:text-base"
                        >
                          {item.label}
                        </Link>
                      )}
                    </NavigationMenuItem>
                  ))}
                </NavigationMenuList>
              </NavigationMenu>
              <Button
                variant="secondary"
                size="sm"
                onClick={toggleLanguage}
                className="bg-white text-primary hover:bg-white/90"
              >
                {language === 'sv' ? 'EN' : 'SV'}
              </Button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}
