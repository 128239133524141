import { motion } from "framer-motion";
import { MainNav } from "../components/MainNav";
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "../components/ui/table";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel";
import { useLanguage } from "../contexts/LanguageContext";

const AboutPage = () => {
  const { language } = useLanguage();

  const content = {
    title: language === 'sv' ? 'Om Petrobell' : 'About Petrobell',
    mainText: language === 'sv' 
      ? [
          'Petrobell tror att en ökad användning av sjövägarna tillhör framtiden. Skillnaden mellan att transportera större som mindre volymer med fartyg istället för tankbil på gatorna är betydande både för människa och miljö. För att på ett miljömässigt och säkert sätt verka efter vår egen vision genomför vi kontinuerligt tekniska uppdateringar av våra fartyg och ser till att dessa drivs med biodrivmedel.',
          'Utmaningarna för Petrobell ligger i att kunna tillhandahålla lösningar på komplexa leveranser på varierande platser med sjönära läge. Ytterligare effektivisering och reducering av vår egen miljöpåverkan når vi genom att jobba med smarta system och digitala informationskällor för exempelvis pejlning av cisterner och tankar. Detta ger möjligheten att i större utsträckning optimera leveranserna så att få kilometer eller nautiska mil tillryggaläggs i onödan.',
          'Den faktor som vi dock alltid sätter högst upp på agendan är säkerhet. På inget sätt skall människa eller miljö behöva riskeras i anslutning till verksamheten.',
          'Genom Petrobells historia finns en stor uppbyggd kunskap kring verksamhet vid och drift av sjöstationer. Därigenom erbjuder Petrobell den kategorin av kunder stöd kring driftfrågor, kontroller och mycket mer.'
        ]
      : [
          'Petrobell believes that increased use of sea routes belongs to the future. The difference between transporting larger and smaller volumes by vessel instead of tank lorry on the streets is significant for both people and the environment. To operate in an environmentally friendly and safe manner according to our own vision, we continuously carry out technical updates of our vessels and ensure that they are powered by biofuels.',
          'The challenges for Petrobell lie in being able to provide solutions for complex deliveries at various locations with proximity to the sea. We achieve further efficiency and reduction of our own environmental impact by working with smart systems and digital information sources, for example for gauging cisterns and tanks. This provides the opportunity to optimise deliveries to a greater extent so that few kilometres or nautical miles are covered unnecessarily.',
          'However, the factor that we always put at the top of the agenda is safety. In no way should people or the environment need to be risked in connection with the operation.',
          'Throughout Petrobell\'s history, there is a great built-up knowledge about operations at and operation of sea stations. Through this, Petrobell offers that category of customers support with advice on operational issues, controls and much more.'
        ],
    ourVessels: language === 'sv' ? 'Våra Fartyg' : 'Our Vessels',
    tableHeaders: {
      name: language === 'sv' ? 'Namn' : 'Name',
      signal: 'Signal',
      capacity: language === 'sv' ? 'Kapacitet' : 'Capacity',
      length: language === 'sv' ? 'Största längd' : 'Maximum length',
      width: language === 'sv' ? 'Största bredd' : 'Maximum width',
      products: language === 'sv' ? 'Produkter' : 'Products'
    },
    imageGallery: language === 'sv' ? 'Bildgalleri' : 'Image Gallery',
    vesselProducts: {
      diesel: language === 'sv' ? 'Diesel' : 'Diesel',
      both: language === 'sv' ? 'Diesel och bensin' : 'Diesel and petrol'
    },
    imageAlts: {
      sunset: language === 'sv' ? 'Bunkerfartyg i vintersolnedgång' : 'Bunker vessel in winter sunset',
      quay: language === 'sv' ? 'Bunkerfartyg vid kaj' : 'Bunker vessel at quay',
      harbor: language === 'sv' ? 'Bunkerfartyg i hamn' : 'Bunker vessel in harbour'
    }
  };

  const vessels = [
    {
      name: "Långbjörn",
      signal: "SDFK",
      capacity: "160 m3",
      length: "29,52 m",
      width: "5,06 m",
      products: content.vesselProducts.diesel
    },
    {
      name: "Bunkerbjörn",
      signal: "SDAR",
      capacity: "95 m3",
      length: "20,33 m",
      width: "5,02 m",
      products: content.vesselProducts.both
    },
    {
      name: "Sjöbjörn 9",
      signal: "SHPW",
      capacity: "110 m3",
      length: "19,38 m",
      width: "5,15 m",
      products: content.vesselProducts.both
    },
    {
      name: "Storbjörn",
      signal: "SEKR",
      capacity: "70 m3",
      length: "18,2 m",
      width: "5,01 m",
      products: content.vesselProducts.both
    },
    {
      name: "Lillbjörn",
      signal: "SFC-7422",
      capacity: "25 m3",
      length: "11,28 m",
      width: "4,02 m",
      products: content.vesselProducts.diesel
    }
  ];

  const carouselImages = [
    {
      src: "/lovable-uploads/1c2e2d7a-0245-44bb-a7c4-f38cf4ed4294.png",
      alt: language === 'sv' ? "Långbjörn bredvid större fartyg" : "Långbjörn next to larger vessel"
    },
    {
      src: "/lovable-uploads/9da44368-4fd3-4ef7-846e-a30edfad8733.png",
      alt: content.imageAlts.quay
    },
    {
      src: "/lovable-uploads/d721b84e-0274-4e39-90c1-82ba44455caa.png",
      alt: language === 'sv' ? "Bunkerfartyg i dimma" : "Bunker vessel in fog"
    }
  ];

  return (
    <div className="min-h-screen w-full">
      <MainNav />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen w-full"
      >
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="w-full h-[400px] mb-12 rounded-lg overflow-hidden">
              <img
                src="/lovable-uploads/d3bb8b77-b7ad-4d92-9ef4-b2f2686aa0da.png"
                alt={language === 'sv' ? "Stockholm stadshus natt" : "Stockholm City Hall night"}
                className="w-full h-full object-cover object-top"
              />
            </div>

            <div className="container mx-auto bg-white p-8 rounded-lg shadow-sm mb-12">
              <h1 className="text-4xl font-bold text-primary mb-8">{content.title}</h1>
              
              <div className="flex flex-col md:flex-row gap-8">
                <div className="md:w-1/2">
                  {content.mainText.map((paragraph, index) => (
                    <p key={index} className="mb-6 text-gray-700">{paragraph}</p>
                  ))}
                </div>
                <div className="md:w-1/2">
                  <div className="rounded-lg overflow-hidden shadow-md h-full">
                    <img
                      src="/lovable-uploads/13faa4c6-89fd-4ccd-b21f-9a5b9cb2cdf7.png"
                      alt={language === 'sv' ? "Tankbil vid fartyg" : "Tanker truck next to ship"}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div data-section="ships" className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-sm mb-12">
              <h2 className="text-3xl font-bold text-primary mb-6">{content.ourVessels}</h2>
              <div className="overflow-x-auto mb-8">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>{content.tableHeaders.name}</TableHead>
                      <TableHead>{content.tableHeaders.signal}</TableHead>
                      <TableHead>{content.tableHeaders.capacity}</TableHead>
                      <TableHead>{content.tableHeaders.length}</TableHead>
                      <TableHead>{content.tableHeaders.width}</TableHead>
                      <TableHead>{content.tableHeaders.products}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {vessels.map((vessel, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-medium">{vessel.name}</TableCell>
                        <TableCell>{vessel.signal}</TableCell>
                        <TableCell>{vessel.capacity}</TableCell>
                        <TableCell>{vessel.length}</TableCell>
                        <TableCell>{vessel.width}</TableCell>
                        <TableCell>{vessel.products}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              <div className="mt-12">
                <h3 className="text-2xl font-semibold text-primary mb-6">{content.imageGallery}</h3>
                <Carousel className="w-full max-w-3xl mx-auto">
                  <CarouselContent>
                    {carouselImages.map((image, index) => (
                      <CarouselItem key={index}>
                        <div className="relative h-[400px] w-full rounded-lg overflow-hidden">
                          <img
                            src={image.src}
                            alt={image.alt}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <CarouselPrevious className="left-2" />
                  <CarouselNext className="right-2" />
                </Carousel>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </div>
  );
};

export default AboutPage;
